import React from 'react'
import { screenSizes } from '../theme'
import _ from 'lodash'

const withScroll = (ComposedComponent, options = { useTop: false }) =>
  class ScrollDecorator extends React.PureComponent {
    constructor(props) {
      super(props)

      this.state = {
        animationProgress: 0,
        entered: false,
      }

      this.animationFrameId = null
      this.initState = this.initState.bind(this)
      this.onScroll = _.throttle(this.onScroll.bind(this), 50)
      this.onResize = _.throttle(this.onResize.bind(this), 50)
      this.setAnimationPercentage = this.setAnimationPercentage.bind(this)
    }

    initState() {
      const originalYOffset =
        this.comp.getBoundingClientRect().y + window.pageYOffset

      this.setState({
        enteredOffset:
          options.enteredOffset != undefined
            ? this.comp.clientHeight * options.enteredOffset
            : this.comp.clientHeight * 0.1,
        startAnimation: originalYOffset,
      })
    }

    componentDidMount() {
      if (window.innerWidth < screenSizes.tablet) {
        this.setState({
          animationProgress: 1,
          entered: true,
        })
      } else {
        this.initState()

        window.addEventListener('scroll', this.onScroll)
        window.addEventListener('resize', this.onResize)
      }
    }

    onScroll() {
      this.animationFrameId = window.requestAnimationFrame(
        this.setAnimationPercentage
      )
    }

    onResize() {
      this.initState()
    }

    componentWillUnmount() {
      window.cancelAnimationFrame(this.animationFrameId)
      window.removeEventListener('scroll', this.onScroll)
      window.removeEventListener('resize', this.onResize)
    }

    setAnimationPercentage() {
      this.windowTop = window.pageYOffset
      const { startAnimation, enteredOffset } = this.state
      const animationPoint = options.useTop
        ? window.pageYOffset
        : window.pageYOffset + window.innerHeight
      const entered = animationPoint >= startAnimation + enteredOffset

      this.setState({
        entered,
      })
    }

    render() {
      const { animationProgress, entered } = this.state

      return (
        <ComposedComponent
          ref={comp =>
            (this.comp = comp && comp.container ? comp.container : comp)
          }
          {...this.props}
          animationProgress={animationProgress}
          entered={entered}
        />
      )
    }
  }

export default withScroll
