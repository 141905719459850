import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import SectionIndicatorBase from './SectionIndicator'
import Arrow from './Arrow'

import { screenSizes, colors } from '../theme'
import Swiper from 'react-id-swiper'
import { TweenLite } from 'gsap'
import WithScrollAnimation from './WithScrollAnimation'

const Container = WithScrollAnimation(
  styled.div`
    display: grid;
    margin: 50px 0;
    max-width: 100%;
    grid-template-columns: 100%;
    @media (min-width: ${screenSizes.desktop}px) {
      margin: 100px 0;
      grid-template-columns: minmax(1%, 20%) 30px 1fr 30px auto auto;
    }
    ul {
      margin-top: 15px;
    }

    li {
      text-transform: uppercase;
      margin-bottom: 0;
      list-style-type: none;
    }

    ${props =>
      props.entered &&
      css`
        ${SlideNumberContainer}, ${SectionIndicatorContainer} {
          transition-delay: 500ms;
        }

        ${SliderContainer} {
          transition-delay: 250ms;
        }

        ${SlideNumber} {
          transition-delay: 0ms;
        }

        ${SlideNumber}, ${SliderContainer}, ${SlideNumberContainer}, ${SectionIndicatorContainer} {
          opacity: 1;
          transform: translate(0, 0);
        }
    `}
  `,
  { enteredOffset: 0.1 }
)

const SlideNumber = styled.p`
  span {
    font-family: 'TanMemories';
  }
  opacity: 0;
  transform: translate(100px, 0px);
  transition: all ease 500ms;
  transition-delay: 500ms;
  font-size: 16pt;
  display: none;
  line-height: 44px;
  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 20pt;
    line-height: 15pt;
  }
  @media (min-width: ${screenSizes.desktop}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (min-width: ${screenSizes.desktopLarge}px) {
    font-size: 22pt;
    line-height: 17pt;
  }
`

const SliderContainer = styled.div`
  opacity: 0;
  transform: translate(100px, 0px);
  transition: all ease 500ms;
  transition-delay: 250ms;

  .swiper-wrapper,
  .swiper-container {
    overflow: visible;
  }

  .swiper-pagination {
    position: relative;
    text-align: left;
    margin-top: 10px;
    padding-left: 35px;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 10pt;
      display: inline-block;
      content: '/';
      color: ${colors.primaryText};
    }

    span {
      margin-right: 25px !important;
      width: auto;
      height: auto;
      background-color: transparent;
      color: ${colors.primaryText};
      font-size: 10pt;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .swiper-slide {
    filter: url(#filter);
    width: 100%;
    opacity: 0;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-column: 2 / 4;

    .swiper-pagination {
      margin-top: 30px;
      padding-left: 50px;

      span {
        margin-right: 40px !important;
      }
    }
  }
`

const SlideTitle = styled.h2`
  /* text-transform: uppercase; */
  font-family: 'TanMemories';
  font-size: 16pt;

  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 20pt;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    font-size: 20pt;
    margin-bottom: calc(1.45rem + 8px + 5pt);
    line-height: 15pt;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    font-size: 24pt;
    line-height: 17pt;
  }
`

const SlideDescription = styled.p`
  width: 100%;
  font-size: 11pt;
  @media (min-width: ${screenSizes.desktop}px) {
    margin-top: -8px;
    max-width: 600px;
  }
`

const SlideNumberContainer = styled.div`
  opacity: 0;
  transform: translate(100px, 0px);
  transition: all ease 500ms;
  transition-delay: 0ms;
  display: none;
  @media (min-width: ${screenSizes.desktop}px) {
    grid-column: 5 / 6;
    display: block;
    text-align: right;

    p {
      line-height: 7pt;
    }
  }
`

const SectionIndicatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transform: translate(100px, 0px);
  transition: all ease 500ms;
  transition-delay: 0ms;

  grid-row-start: 1;

  svg {
    display: none;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-row-start: auto;
    flex-direction: column;
    align-items: flex-end;

    svg {
      display: block;
    }
  }
`

const SectionIndicator = styled(SectionIndicatorBase)`
  margin-bottom: 20px;
  @media (min-width: ${screenSizes.desktop}px) {
    margin-left: 40px;
    margin-bottom: 0;
    line-height: 7pt;
  }
`

class TextSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0,
      animate: false,
      swiperParams: null,
    }

    this.timeout = null
    this.swiper = null
    this.container = null
    this.onArrowClick = this.onArrowClick.bind(this)
    this.setSlideTranslate = this.setSlideTranslate.bind(this)
    this.resetAnimation = this.resetAnimation.bind(this)
    this.setTranslate = this.setTranslate.bind(this)
  }

  static propTypes = {
    sectionTitle: PropTypes.string,
    slides: PropTypes.array,
    className: PropTypes.string,
  }

  onArrowClick() {
    window.scrollTo({
      top:
        window.pageYOffset +
        this.container.comp.getBoundingClientRect().y +
        this.container.comp.clientHeight,
      behavior: 'smooth',
    })
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
    if (this.swiper) {
      this.swiper.destroy()
    }
  }

  componentDidMount() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const devicePixelRatio = window.devicePixelRatio
    const useWaveEffect =
      (isSafari == false || devicePixelRatio < 2) &&
      window.innerWidth > screenSizes.tablet

    const swiperParams = {
      containerClass: 'swiper-container swiper-container-text',
      touchStartPreventDefault: false,
      slidesPerView: 1,
      watchSlidesProgress: useWaveEffect ? true : false,
      watchSlidesVisibility: true,
      virtualTranslate: useWaveEffect ? true : false,
      effect: useWaveEffect ? 'wave' : 'fade',
      fadeEffect: {
        crossFade: useWaveEffect ? false : true,
      },
      touchEventsTarget: 'wrapper',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}">${index + 1}</span>`
        },
      },
      on: {
        slideChange: () => {
          let activeSlide = this.swiper.activeIndex
          this.setState({ activeSlide })
          if (useWaveEffect) {
            this.resetAnimation()
          }
        },
        setTranslate: useWaveEffect ? this.setTranslate : () => {},
      },
    }

    this.setState({ swiperParams }, () => {
      if (useWaveEffect) {
        this.setSlideTranslate()
      }
    })
  }

  setSlideTranslate() {
    if (this.swiper && this.swiper.slides) {
      this.swiper.slides.each(slideKey => {
        const slide = this.swiper.slides[slideKey]
        const offset = slide.swiperSlideOffset
        let x = -offset - 10
        let y = -10
        TweenLite.set(slide, {
          x,
          y,
        })
      })
    }
  }

  resetAnimation() {
    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      TweenLite.to(this.filter, 0.5, {
        attr: {
          baseFrequency: `0 0`,
        },
      })
    }, 250)
  }

  setTranslate() {
    if (this.swiper && this.swiper.slides) {
      this.setSlideTranslate()
      this.swiper.slides.each(slideKey => {
        const slide = this.swiper.slides[slideKey]

        const opacity = Math.max(1 - Math.abs(slide.progress), 0)

        TweenLite.to(slide, 0.5, {
          opacity,
        })
      })
      const frequency =
        0.1 * Math.abs(this.swiper.slides[this.swiper.activeIndex].progress)
      TweenLite.to(this.filter, 0.5, {
        attr: {
          baseFrequency: `${frequency} ${frequency}`,
        },
      })
    }
  }

  render() {
    const { activeSlide, swiperParams } = this.state
    const { sectionTitle, slides, className } = this.props

    return (
      <>
        {swiperParams && swiperParams.effect === 'wave' && (
          <svg height="0">
            <filter
              id="filter"
              x="-20%"
              y="-20%"
              width="140%"
              height="140%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="linearRGB"
            >
              <feTurbulence
                ref={ref => (this.filter = ref)}
                type="turbulence"
                baseFrequency="0 0"
                numOctaves="1"
                seed="2"
                stitchTiles="noStitch"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                result="turbulence"
              />
              <feDisplacementMap
                in="SourceGraphic"
                in2="turbulence"
                scale="20"
                xChannelSelector="R"
                yChannelSelector="B"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                result="displacementMap"
              />
            </filter>
          </svg>
        )}
        <Container
          className={className}
          ref={container => (this.container = container)}
        >
          <SlideNumber>
            <span>
              {activeSlide >= 10 ? activeSlide + 1 : `0${activeSlide + 1}`}
            </span>
            <Arrow onClick={this.onArrowClick} />
          </SlideNumber>
          <SliderContainer>
            {swiperParams && (
              <Swiper
                {...swiperParams}
                ref={node => {
                  if (node) this.swiper = node.swiper
                }}
              >
                {slides.map((slide, index) => {
                  return (
                    <div key={index}>
                      {slide.title && <SlideTitle>{slide.title}</SlideTitle>}
                      <SlideDescription>{slide.body}</SlideDescription>
                    </div>
                  )
                })}
              </Swiper>
            )}
          </SliderContainer>
          <SlideNumberContainer>
            <p>
              {slides[activeSlide].description ? (
                slides[activeSlide].description
              ) : (
                <span>
                  {activeSlide + 1} &mdash; {slides.length}
                </span>
              )}
            </p>
          </SlideNumberContainer>
          <SectionIndicatorContainer>
            <SectionIndicator>{sectionTitle}</SectionIndicator>
          </SectionIndicatorContainer>
        </Container>
      </>
    )
  }
}

export default TextSlider
